<template>
  <div class="layout-content">
    <h1>我的参数</h1>
    <small>更新日期：2020-08-06</small>
    <el-row type="flex">
      <el-col :span="12">
        <BodyInfo />
      </el-col>
      <el-col></el-col>
    </el-row>
  </div>
</template>

<script>
import { store } from "../store";
import BodyInfo from "../components/gym/BodyInfo";
export default {
  components: {
    BodyInfo,
  },
  mounted() {
    // 切换主题
    store.setTheme("light");
  },
};
</script>

<style lang="less" slot-scope>
</style>
