<template>
  <div class="slider-bg">
    <div class="slider" :style="{left: `-${100-leftDistance}%`}"></div>
  </div>
</template>

<script>
// import { store } from "../store";
export default {
  name: "Slider",
  props: { max: Number, value: Number, color: String },
  computed: {
    leftDistance() {
      if (this.value >= this.max) {
        return 100;
      } else if (this.value == 0) {
        return 0;
      } else {
        return this.value / (this.max / 100);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.slider-bg {
  background-color: #e4e4e4;
  width: 100%;
  height: 12px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}
.slider {
  width: 100%;
  height: 100%;
  background-color: #38f;
  position: absolute;
  // left: -41px;
}
</style>
