<template>
  <div>
    <el-row type="flex" justify="space-between" v-for="(i, index) in infoList" :key="index">
      <el-col :span="4">{{i.name}}</el-col>
      <el-col :span="20">
        <el-row type="flex" justify="start" align="middle" :gutter="10" style="height: 100%">
          <el-col :span="18">
            <Slider :value="i.value" :max="i.max" />
          </el-col>
          <el-col :span="3" class="info-value">{{i.value}}</el-col>
          <el-col :span="3" class="info-unit">{{i.unit}}</el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import { store } from "../store";
import Slider from "../ui/Slider";
export default {
  name: "BodyInfo",
  components: {
    Slider,
  },
  props: { title: String, subTitle: String, linkList: Array },
  data: () => {
    return {
      infoList: [
        { name: "身高", unit: "cm", value: 172, max: 200 },
        { name: "体重", unit: "kg", value: 68, max: 200 },
        { name: "胸围", unit: "cm", value: 102, max: 200 },
        { name: "腰围", unit: "cm", value: 84, max: 200 },
        { name: "臀围", unit: "cm", value: 90, max: 200 },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.info-value {
  text-align: right;
  color: rgb(38, 144, 237);
  font-weight: bold;
}
.info-unit {
  color: #999;
}
</style>
